import React from 'react'
import PropTypes from 'prop-types'
import ImgIntro from '../../images/svgs/stage_home_min.svg'
import './HeaderFrontpage.css'
import Button from '../Button/Button'

const Header = ({ className, titleBig, teaserText ,color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full flex flex-wrap px-4 md:px-8 pb-6 lg:pb-16 pt-0 md:pt-16 flex justify-center v5 bg-${color} ${className}`}>
        <div className={`flex w-full max-w-4xl flex-wrap`}>
          <h1 className={`stage-title text-6xl w-full self-start text-center hyphens-manual ${textcolor}`}>Per Anhalter zur Digitali&shy;sierung.</h1>
        </div>
        <div className='flex w-full xs:my-16 lg:my-24 px-6 md:px-8 lg:px-16 justify-center'>
          <div className='flex w-full max-w-5xl flex-wrap justify-center'>
            
            <div className='flex flex-col md:w-5/12 max-w-4xl intro-stage-text pt-8'>
              <p className='mt-8 text-white'>Unsere Mission ist Sie bei dieser Herausforderung der Digitalisierung zu unterstützen, egal ob Smartphone-App, Website, Backend, Design und Beratung. Wir sind ein Team aus Entwicklern, Gestaltern und Unternehmen.</p>
              <Button to='/projekt-starten' withArrow className='w-max-content self-start p-3 mt-8 mb-8 btn-filled btn-yellow-outline'>Projekt starten</Button>
            </div>
            <div className='flex flex-col md:w-7/12 max-w-4xl pr-8 mt-8 md:-mt-8'>
              <p className=''><ImgIntro className='w-full illu-stage'/></p>
            </div>
          </div>
      </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  teaserText: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header
